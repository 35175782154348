import React from "react";
import { graphql } from "gatsby";

import DefaultLayout from "../layouts/default";
import HeaderTitle from "../components/HeaderTitle";
import GreenLead from "../components/GreenLead";
import TabContainer from "../components/TabContainer";
import JumpToTop from "../components/JumpToTop";

interface HerstellerDownloadsProps {
  data: {
    cms: {
      entry: {
        title: string;
        greenLead: string;
        children: [
          {
            title: string;
            children: [
              {
                title: string;
                itemImage: [{ url: string; title: string }];
                youTubeUrl: string;
                itemDescription: string;
              }
            ];
          }
        ];
      };
    };
  };
}

export const HerstellerDownloadsQuery = graphql`
  query ($uri: String!, $siteId: CMS_QueryArgument) {
    cms {
      entry(uri: [$uri], siteId: [$siteId]) {
        siteId
        title
        # localized {
        #   uri
        #   siteId
        # }
        ... on CMS_main_herstellerDownloads_Entry {
          greenLead
        }
        children {
          title
          ... on CMS_main_kategorie_Entry {
            kategorieInhalt {
              ... on CMS_kategorieInhalt_youTubeUrl_BlockType {
                youTubeUrl
              }
              ... on CMS_kategorieInhalt_lead_BlockType {
                lead
              }
            }
          }
          children {
            title
            ... on CMS_main_herstellerDownloadsItem_Entry {
              itemImage {
                url
                title
              }
              youTubeUrl
              itemDescription
            }
          }
        }
      }
    }
  }
`;

const HerstellerDownloads: React.FC<HerstellerDownloadsProps> = ({ data }) => (
  <DefaultLayout
    siteId={data.cms.entry.siteId}
    title={data.cms.entry.title}
    //links={data.cms.entry.localized}
  >
    <HeaderTitle inhalt={data.cms.entry.title} />
    <GreenLead inhalt={data.cms.entry.greenLead} />
    <TabContainer downloads inhalt={data.cms.entry.children} />
  </DefaultLayout>
);

export default HerstellerDownloads;
